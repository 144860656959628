export const formatDashboardData = (data) => {
  console.log(data);
  const formattedData = {
    overview: {
      primaryOverview: [
        {
          title: "Total views",
          name: "totalViews",
          value: data?.overview?.totalViews,
          hasMore: true,
          more: [
            {
              title: "Private",
              value: data?.overviewUser?.totalViews,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: data?.overviewDealer?.totalViews,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total users",
          name: "totalUsers",
          value: data?.overview?.totalUsers,
          hasMore: true,
          more: [
            {
              title: "Private",
              value: data?.overview?.totalPrivateUsers,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: data?.overview?.totalDealers,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total active ads",
          name: "totalActiveAds",
          value: data?.overview?.totalActiveAds,
          hasMore: true,
          more: [
            {
              title: "Private",
              value: data?.overviewUser?.totalActiveAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: data?.overviewDealer?.totalActiveAds,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total active ads (Sell)",
          name: "totalActiveAdsSell",
          value: data?.overview?.totalSellAds,
          hasMore: true,
          more: [
            {
              title: "Private",
              value: data?.overviewUser?.totalSellAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: data?.overviewDealer?.totalSellAds,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total active ads (Rent)",
          name: "totalActiveAdsRent",
          value: data?.overview?.totalRentedAds,
          hasMore: true,
          more: [
            {
              title: "Private",
              value: data?.overviewUser?.totalRentedAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: data?.overviewDealer?.totalRentedAds,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total chats sent",
          name: "totalChats",
          value:
            data?.overviewUser?.totalChatInit +
            data?.overviewDealer?.totalChatInit,
          hasMore: true,
          more: [
            {
              title: "Private",
              value: data?.overviewUser?.totalChatInit,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: data?.overviewDealer?.totalChatInit,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total chats recieved",
          name: "totalChats",
          value:
            data?.overviewUser?.totalReceivedChats +
            data?.overviewDealer?.totalReceivedChats,
          hasMore: true,
          more: [
            {
              title: "Private",
              value: data?.overviewUser?.totalReceivedChats,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: data?.overviewDealer?.totalReceivedChats,
              badgeColor: "#437ee8",
            },
          ],
        },
        // {
        //   title: "Total calls send",
        //   name: "totlaCalls",
        //   value:
        //     data?.overviewUser?.totalPhoneCalls +
        //     data?.overviewDealer?.totalPhoneCalls,
        //   hasMore: true,
        //   more: [
        //     {
        //       title: "Private",
        //       value: data?.overviewUser?.totalPhoneCalls,
        //       badgeColor: "#437ee8",
        //     },
        //     {
        //       title: "Dealer",
        //       value: data?.overviewDealer?.totalPhoneCalls,
        //       badgeColor: "#437ee8",
        //     },
        //   ],
        // },
        {
          title: "Total calls recieved",
          name: "totlaCalls",
          value:
            data?.overviewUser?.userCallReceives +
            data?.overviewDealer?.dealerCallReceives,
          hasMore: true,
          more: [
            {
              title: "Private",
              value: data?.overviewUser?.userCallReceives,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: data?.overviewDealer?.dealerCallReceives,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total ads sold",
          name: "totalActiveAdsRent",
          value: data?.overviewUser?.soldAds + data?.overviewDealer?.soldAds,
          hasMore: true,
          more: [
            {
              title: "Private",
              value: data?.overviewUser?.soldAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: data?.overviewDealer?.soldAds,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total VIN searches",
          name: "totalVinSearches",
          value: data?.overview?.totalVinTransactions,
          hasMore: true,
          more: [
            {
              title: "Private",
              value: data?.overviewUser?.totalVinTransactions,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: data?.overviewDealer?.totalVinTransactions,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total Pending Ads",
          name: "totalPendingAds",
          value: data?.overview?.totalPendingAds,
          hasMore: true,
          more: [
            {
              title: "Private",
              value: data?.overviewUser?.totalPendingAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: data?.overviewDealer?.totalPendingAds,
              badgeColor: "#437ee8",
            },
          ],
        },
      ],
      advertsOverview: [
        {
          title: "Total ads created",
          name: "totalAds",
          value: data?.overview?.allAds,
          hasMore: true,
          more: [
            {
              title: "Private",
              value: data?.overviewUser?.allAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: data?.overviewDealer?.allAds,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total Inactive Ads",
          name: "totalInactiveAds",
          value: data?.overview?.totalInactiveAds,
          hasMore: true,
          more: [
            {
              title: "Private",
              value: data?.overviewUser?.totalInactiveAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: data?.overviewDealer?.totalInactiveAds,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total (Pending Payment)",
          name: "incompleteAds",
          value: data?.overview?.incompleteAds,
          hasMore: true,
          more: [
            {
              title: "Private",
              value: data?.overviewUser?.incompleteAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: data?.overviewDealer?.incompleteAds,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total Ads Delisted",
          name: "deletedAds",
          value: data?.overview?.deletedAds,
          hasMore: true,
          more: [
            {
              title: "Private",
              value: data?.overviewUser?.deletedAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: data?.overviewDealer?.deletedAds,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total ads expired",
          name: "deletedAds",
          value:
            data?.overviewUser?.expiredAds + data?.overviewDealer?.expiredAds,
          hasMore: true,
          more: [
            {
              title: "Private",
              value: data?.overviewUser?.expiredAds,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: data?.overviewDealer?.expiredAds,
              badgeColor: "#437ee8",
            },
          ],
        },
      ],
      paymentsOverview: [
        {
          title: "Total payment recieved through ad postings",
          name: "totalViews",
          value: `$${data?.overview?.totalPaymentRecieved?.totalAdsSum?.toFixed(
            2
          )}`,
          hasMore: true,
          more: [
            {
              title: "Private",
              value: `$${data?.overviewUser?.totalPaymentRecieved?.totalAdsSum?.toFixed(
                2
              )}`,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: `$${data?.overviewDealer?.totalPaymentRecieved?.totalAdsSum?.toFixed(
                2
              )}`,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total payment recieved through VIN searches",
          name: "totalViews",
          value: `$${data?.overview?.totalPaymentRecieved?.totalVinSum?.toFixed(
            2
          )}`,
          hasMore: true,
          more: [
            {
              title: "Private",
              value: `$${data?.overviewUser?.totalPaymentRecieved?.totalVinSum?.toFixed(
                2
              )}`,
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value: `$${data?.overviewDealer?.totalPaymentRecieved?.totalVinSum?.toFixed(
                2
              )}`,
              badgeColor: "#437ee8",
            },
          ],
        },
        {
          title: "Total payment received",
          name: "totalPaymentRecieved",
          value:
            "$" +
            (
              data?.overview?.totalPaymentRecieved?.totalVinSum +
              data?.overview?.totalPaymentRecieved?.totalAdsSum
            )?.toFixed(2),
          currency: "$",
          hasMore: true,
          more: [
            {
              title: "Private",
              value:
                "$" +
                (
                  data?.overviewUser?.totalPaymentRecieved?.totalVinSum +
                  data?.overviewUser?.totalPaymentRecieved?.totalAdsSum
                )?.toFixed(2),
              badgeColor: "#437ee8",
            },
            {
              title: "Dealer",
              value:
                "$" +
                (
                  data?.overviewDealer?.totalPaymentRecieved?.totalVinSum +
                  data?.overviewDealer?.totalPaymentRecieved?.totalAdsSum
                )?.toFixed(2),
              badgeColor: "#437ee8",
            },
          ],
        },
      ],
    },
    keyStats: {
      dealer: {
        stats: [
          {
            title: "Ultimate",
            name: "ultimate",
            value: data?.keyStats?.dealer?.ultimate,
          },
          {
            title: "Premium",
            name: "premium",
            value: data?.keyStats?.dealer?.premium,
          },
          {
            title: "Standard",
            name: "standard",
            value: data?.keyStats?.dealer?.standard,
          },
          {
            title: "Basic",
            name: "basic",
            value: data?.keyStats?.dealer?.basic,
          },
        ],
        additionalInfo: [
          {
            content:
              data?.keyStats?.dealer?.additionalInfo?.overallAds +
              " ads posted so far",
            image: "akar-icons_info-fill.png",
          },
          {
            content: data?.overviewDealer?.soldAds + " cars sold",
            image: "akar-icons_info-fill.png",
          },
          {
            content: data?.overviewDealer?.deletedAds + " cars deleted",
            image: "akar-icons_info-fill.png",
          },
        ],
      },
      users: {
        stats: [
          {
            title: "Ultimate",
            name: "ultimate",
            value: data?.keyStats?.users?.ultimate,
          },
          {
            title: "Premium",
            name: "premium",
            value: data?.keyStats?.users?.premium,
          },
          {
            title: "Standard",
            name: "standard",
            value: data?.keyStats?.users?.standard,
          },
          {
            title: "Basic",
            name: "basic",
            value: data?.keyStats?.users?.basic,
          },
        ],
        additionalInfo: [
          {
            content:
              data?.keyStats?.users?.additionalInfo?.overallAds +
              " ads posted so far",
            image: "akar-icons_info-fill.png",
          },
          {
            content: data?.overviewUser?.soldAds + " cars sold",
            image: "akar-icons_info-fill.png",
          },
          {
            content: data?.overviewUser?.deletedAds + " cars deleted",
            image: "akar-icons_info-fill.png",
          },
        ],
      },
      // total_car_sold: {
      //   title: " Cars sold so far (dealers  &  private users)",
      //   name: "totalSoldCars",
      //   value:
      //     data?.keyStats?.dealer?.additionalInfo?.soldAds +
      //     data?.keyStats?.users?.additionalInfo?.soldAds,
      // },
    },
    metrics: [
      {
        title: "Views on selling cars",
        name: "totalSellingViews",
        value: data?.metrics?.totalSellingViews,
      },
      {
        title: "Views on rental cars",
        name: "totalRentingViews",
        value: data?.metrics?.totalRentingViews,
      },
      {
        title: "Views on checking cars",
        name: "totalCheckCarViews",
        value: data?.metrics?.totalCheckCarViews,
      },
      {
        title: "Views on website",
        name: "websiteViews",
        value: data?.metrics?.websiteViews,
      },
      {
        title: "Views on app",
        name: "mobileAppViews",
        value: data?.metrics?.mobileAppViews,
      },
      //   {
      //     title: "Total views",
      //     name: "totalVisits",
      //     value: data?.metrics?.totalVisits,
      //   },
    ],
    graphs: {
      liveTrafficGraph: {
        title: "Live traffic",
        description: "Product trends by month",
        data: [10000, 15000, 20000, 15000, 50000, 30000, 40000],
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
      soldGraph: {
        title: "Selling rate",
        description: "Products sold by month",
        data: [800, 150, 200, 150, 200, 300, 900],
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
      rentedGraph: {
        title: "Renting rate",
        description: "Products rented by month",
        data: [400, 150, 200, 150, 300, 300, 400],
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
    },
  };
  return formattedData;
};

// export const formatDashboardData = (data) => {
//   console.log(data);
//   const formattedData = {
//     overview: {
//       advertsOverview: [
//         {
//           title: "Total ads created",
//           name: "totalAds",
//           value: data?.overview?.allAds,
//           hasMore: true,
//           more: [
//             {
//               title: "Private",
//               value: data?.overviewUser?.allAds,
//               badgeColor: "#437ee8",
//             },
//             {
//               title: "Dealer",
//               value: data?.overviewDealer?.allAds,
//               badgeColor: "#437ee8",
//             },
//           ],
//         },
//         {
//           title: "Total active ads",
//           name: "totalActiveAds",
//           value: data?.overview?.totalActiveAds,
//           hasMore: true,
//           more: [
//             {
//               title: "Private",
//               value: data?.overviewUser?.totalActiveAds,
//               badgeColor: "#437ee8",
//             },
//             {
//               title: "Dealer",
//               value: data?.overviewDealer?.totalActiveAds,
//               badgeColor: "#437ee8",
//             },
//           ],
//         },
//         {
//           title: "Total active ads (Sell)",
//           name: "totalActiveAdsSell",
//           value: data?.overview?.totalSellAds,
//           hasMore: true,
//           more: [
//             {
//               title: "Private",
//               value: data?.overviewUser?.totalSellAds,
//               badgeColor: "#437ee8",
//             },
//             {
//               title: "Dealer",
//               value: data?.overviewDealer?.totalSellAds,
//               badgeColor: "#437ee8",
//             },
//           ],
//         },
//         {
//           title: "Total active ads (Rent)",
//           name: "totalActiveAdsRent",
//           value: data?.overview?.totalRentedAds,
//           hasMore: true,
//           more: [
//             {
//               title: "Private",
//               value: data?.overviewUser?.totalRentedAds,
//               badgeColor: "#437ee8",
//             },
//             {
//               title: "Dealer",
//               value: data?.overviewDealer?.totalRentedAds,
//               badgeColor: "#437ee8",
//             },
//           ],
//         },
//         {
//           title: "Total ads sold",
//           name: "totalActiveAdsRent",
//           value: data?.overviewUser?.soldAds + data?.overviewDealer?.soldAds,
//           hasMore: true,
//           more: [
//             {
//               title: "Private",
//               value: data?.overviewUser?.soldAds,
//               badgeColor: "#437ee8",
//             },
//             {
//               title: "Dealer",
//               value: data?.overviewDealer?.soldAds,
//               badgeColor: "#437ee8",
//             },
//           ],
//         },
//         {
//           title: "Total Pending Ads",
//           name: "totalPendingAds",
//           value: data?.overview?.totalPendingAds,
//           hasMore: true,
//           more: [
//             {
//               title: "Private",
//               value: data?.overviewUser?.totalPendingAds,
//               badgeColor: "#437ee8",
//             },
//             {
//               title: "Dealer",
//               value: data?.overviewDealer?.totalPendingAds,
//               badgeColor: "#437ee8",
//             },
//           ],
//         },
//         {
//           title: "Total Inactive Ads",
//           name: "totalInactiveAds",
//           value: data?.overview?.totalInactiveAds - (data?.overviewUser?.soldAds + data?.overviewDealer?.soldAds),
//           hasMore: true,
//           more: [
//             {
//               title: "Private",
//               value: data?.overviewUser?.totalInactiveAds - data?.overviewUser?.soldAds || "0",
//               badgeColor: "#437ee8",
//             },
//             {
//               title: "Dealer",
//               value: data?.overviewDealer?.totalInactiveAds - data?.overviewDealer?.soldAds || "0",
//               badgeColor: "#437ee8",
//             },
//           ],
//         },
//         {
//           title: "Total Ads Incomplete",
//           name: "incompleteAds",
//           value: data?.overview?.incompleteAds,
//           hasMore: true,
//           more: [
//             {
//               title: "Private",
//               value: data?.overviewUser?.incompleteAds,
//               badgeColor: "#437ee8",
//             },
//             {
//               title: "Dealer",
//               value: data?.overviewDealer?.incompleteAds,
//               badgeColor: "#437ee8",
//             },
//           ],
//         },
//         {
//           title: "Total Ads Deleted",
//           name: "deletedAds",
//           value: data?.overview?.deletedAds,
//           hasMore: true,
//           more: [
//             {
//               title: "Private",
//               value: data?.overviewUser?.deletedAds,
//               badgeColor: "#437ee8",
//             },
//             {
//               title: "Dealer",
//               value: data?.overviewDealer?.deletedAds,
//               badgeColor: "#437ee8",
//             },
//           ],
//         },
//       ],
//       paymentsOverview: [
//         {
//           title: "Total payment recieved through ad postings",
//           name: "totalViews",
//           value: `$${data?.overview?.totalPaymentRecieved?.totalAdsSum?.toFixed(
//             2
//           )}`,
//           hasMore: true,
//           more: [
//             {
//               title: "Private",
//               value: `$${data?.overviewUser?.totalPaymentRecieved?.totalAdsSum?.toFixed(
//                 2
//               )}`,
//               badgeColor: "#437ee8",
//             },
//             {
//               title: "Dealer",
//               value: `$${data?.overviewDealer?.totalPaymentRecieved?.totalAdsSum?.toFixed(
//                 2
//               )}`,
//               badgeColor: "#437ee8",
//             },
//           ],
//         },
//         {
//           title: "Total payment recieved through VIN searches",
//           name: "totalViews",
//           value: `$${data?.overview?.totalPaymentRecieved?.totalVinSum?.toFixed(
//             2
//           )}`,
//           hasMore: true,
//           more: [
//             {
//               title: "Private",
//               value: `$${data?.overviewUser?.totalPaymentRecieved?.totalVinSum?.toFixed(
//                 2
//               )}`,
//               badgeColor: "#437ee8",
//             },
//             {
//               title: "Dealer",
//               value: `$${data?.overviewDealer?.totalPaymentRecieved?.totalVinSum?.toFixed(
//                 2
//               )}`,
//               badgeColor: "#437ee8",
//             },
//           ],
//         },
//         {
//           title: "Total payment received",
//           name: "totalPaymentRecieved",
//           value:
//             "$" +
//             (
//               data?.overview?.totalPaymentRecieved?.totalVinSum +
//               data?.overview?.totalPaymentRecieved?.totalAdsSum
//             )?.toFixed(2),
//           currency: "$",
//           hasMore: true,
//           more: [
//             {
//               title: "Private",
//               value:
//                 "$" +
//                 (
//                   data?.overviewUser?.totalPaymentRecieved?.totalVinSum +
//                   data?.overviewUser?.totalPaymentRecieved?.totalAdsSum
//                 )?.toFixed(2),
//               badgeColor: "#437ee8",
//             },
//             {
//               title: "Dealer",
//               value:
//                 "$" +
//                 (
//                   data?.overviewDealer?.totalPaymentRecieved?.totalVinSum +
//                   data?.overviewDealer?.totalPaymentRecieved?.totalAdsSum
//                 )?.toFixed(2),
//               badgeColor: "#437ee8",
//             },
//           ],
//         },
//       ],
//       misc: [
//         {
//           title: "Total users",
//           name: "totalUsers",
//           value: data?.overview?.totalUsers,
//           hasMore: true,
//           more: [
//             {
//               title: "Private",
//               value: data?.overview?.totalPrivateUsers,
//               badgeColor: "#437ee8",
//             },
//             {
//               title: "Dealer",
//               value: data?.overview?.totalDealers,
//               badgeColor: "#437ee8",
//             },
//           ],
//         },
//         {
//           title: "Total Enquiries",
//           name: "totalViews",
//           value:
//             data?.overviewUser?.totalChatInit +
//             data?.overviewDealer?.totalChatInit,
//           hasMore: true,
//           more: [
//             {
//               title: "Private",
//               value: data?.overviewUser?.totalChatInit,
//               badgeColor: "#437ee8",
//             },
//             {
//               title: "Dealer",
//               value: data?.overviewDealer?.totalChatInit,
//               badgeColor: "#437ee8",
//             },
//           ],
//         },
//         {
//           title: "Total calls",
//           name: "totalViews",
//           value:
//             data?.overviewUser?.totalPhoneCalls +
//             data?.overviewDealer?.totalPhoneCalls,
//           hasMore: true,
//           more: [
//             {
//               title: "Private",
//               value: data?.overviewUser?.totalPhoneCalls,
//               badgeColor: "#437ee8",
//             },
//             {
//               title: "Dealer",
//               value: data?.overviewDealer?.totalPhoneCalls,
//               badgeColor: "#437ee8",
//             },
//           ],
//         },
//         {
//           title: "Total views",
//           name: "totalViews",
//           value: data?.overview?.totalViews,
//         },
//         {
//           title: "Total VIN searches",
//           name: "totalViews",
//           value: data?.overview?.totalVinTransactions,
//         },
//       ],
//     },
//     keyStats: {
//       dealer: {
//         stats: [
//           {
//             title: "Ultimate",
//             name: "ultimate",
//             value: data?.keyStats?.dealer?.ultimate,
//           },
//           {
//             title: "Premium",
//             name: "premium",
//             value: data?.keyStats?.dealer?.premium,
//           },
//           {
//             title: "Standard",
//             name: "standard",
//             value: data?.keyStats?.dealer?.standard,
//           },
//           {
//             title: "Basic",
//             name: "basic",
//             value: data?.keyStats?.dealer?.basic,
//           },
//         ],
//         additionalInfo: [
//           {
//             content:
//               data?.keyStats?.dealer?.additionalInfo?.overallAds +
//               " ads posted so far",
//             image: "akar-icons_info-fill.png",
//           },
//           {
//             content:
//               data?.keyStats?.dealer?.additionalInfo?.soldAds + " cars sold",
//             image: "akar-icons_info-fill.png",
//           },
//           {
//             content:
//               data?.keyStats?.dealer?.additionalInfo?.deletedAds +
//               " cars deleted",
//             image: "akar-icons_info-fill.png",
//           },
//         ],
//       },
//       users: {
//         stats: [
//           {
//             title: "Ultimate",
//             name: "ultimate",
//             value: data?.keyStats?.users?.ultimate,
//           },
//           {
//             title: "Premium",
//             name: "premium",
//             value: data?.keyStats?.users?.premium,
//           },
//           {
//             title: "Standard",
//             name: "standard",
//             value: data?.keyStats?.users?.standard,
//           },
//           {
//             title: "Basic",
//             name: "basic",
//             value: data?.keyStats?.users?.basic,
//           },
//         ],
//         additionalInfo: [
//           {
//             content:
//               data?.keyStats?.users?.additionalInfo?.overallAds +
//               " ads posted so far",
//             image: "akar-icons_info-fill.png",
//           },
//           {
//             content:
//               data?.keyStats?.users?.additionalInfo?.soldAds + " cars sold",
//             image: "akar-icons_info-fill.png",
//           },
//           {
//             content:
//               data?.keyStats?.users?.additionalInfo?.deletedAds +
//               " cars deleted",
//             image: "akar-icons_info-fill.png",
//           },
//         ],
//       },
//       total_car_sold: {
//         title: " Cars sold so far (dealers  &  private users)",
//         name: "totalSoldCars",
//         value:
//           data?.keyStats?.dealer?.additionalInfo?.soldAds +
//           data?.keyStats?.users?.additionalInfo?.soldAds,
//       },
//     },
//     metrics: [
//       {
//         title: "Views on selling cars",
//         name: "totalSellingViews",
//         value: data?.metrics?.totalSellingViews,
//       },
//       {
//         title: "Views on rental cars",
//         name: "totalRentingViews",
//         value: data?.metrics?.totalRentingViews,
//       },
//       {
//         title: "Views on checking cars",
//         name: "totalCheckCarViews",
//         value: data?.metrics?.totalCheckCarViews,
//       },
//       {
//         title: "Views on website",
//         name: "websiteViews",
//         value: data?.metrics?.websiteViews,
//       },
//       {
//         title: "Views on app",
//         name: "mobileAppViews",
//         value: data?.metrics?.mobileAppViews,
//       },
//       //   {
//       //     title: "Total views",
//       //     name: "totalVisits",
//       //     value: data?.metrics?.totalVisits,
//       //   },
//     ],
//     graphs: {
//       liveTrafficGraph: {
//         title: "Live traffic",
//         description: "Product trends by month",
//         data: [10000, 15000, 20000, 15000, 50000, 30000, 40000],
//         categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
//       },
//       soldGraph: {
//         title: "Selling rate",
//         description: "Products sold by month",
//         data: [800, 150, 200, 150, 200, 300, 900],
//         categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
//       },
//       rentedGraph: {
//         title: "Renting rate",
//         description: "Products rented by month",
//         data: [400, 150, 200, 150, 300, 300, 400],
//         categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
//       },
//     },
//   };
//   return formattedData;
// };
